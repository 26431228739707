import React, { useState } from "react";
import { updatePrestationInArray } from "../../redux/prestations/prestationsActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  iniciarPrestacionTrx,
  setPrestation,
  removePrestacionTrx,
  updatePrestation,
} from "../../redux/prestation/prestationActions";
import { ConfirmacionSiNoModal } from "../ConfirmacionSiNoModal/index";
import { useToasts } from "react-toast-notifications";
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  TablaBodyTextoDiente,
  StyledInputNota,
} from "./styles";
import Spinner from "react-bootstrap/Spinner";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const Fila = ({ item, onClickIniciar }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [cambio, setCambio] = React.useState(false);
  
  const prestacion = useSelector((state) => state.prestacion);


  React.useEffect(()=>{
    //guarda el valor original una sola vez
    if (!item.precioOrigin)
      item.precioOrigin = item.precio
      setPrestation(item);
  },[])

  const handleEliminar = (item) => {
    try {
      validar(item);
      dispatch(setPrestation(item));
      if (item.estado === "PENDIENTE") dispatch(removePrestacionTrx(item));
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validar = (prestacion) => {
    if (prestacion.pagado > 0)
      throw new Error(
        "No se puede eliminar la prestación, tiene pagos recibidos"
      );
  };
  const handleChange = (e) => {
    try {
      let myItem = Object.assign({}, item);
      setCambio(true);
      if (e.target.name === "precio") 
      {
        let dscto = (myItem.precioOrigin - Number(e.target.value))/(myItem.precioOrigin)*100
        myItem.dscto = dscto
        myItem.precio = Number(e.target.value)
      };
      if (e.target.name === "nota") myItem.nota = e.target.value;

      if (e.target.name === "dscto") 
      {
        myItem.dscto = Number(e.target.value)
        myItem.precio = myItem.precioOrigin - (myItem.precioOrigin*Number(e.target.value)/100);
        //setDscto(Number(e.target.value))
      }
      if (item.estado === "ACTIVO")
        throw new Error("Prestacion en estado activo, no permite modificación");
      dispatch(setPrestation(myItem));
      dispatch(updatePrestationInArray(myItem));
    } catch (err) {
      addToast(err.message, {
        appearance: "error",
      });
    }
  };

  const handleBlur = (e) => {
    try {
      if (cambio) dispatch(updatePrestation(prestacion));
      setCambio(false);
    } catch (err) {
      addToast(err.message, {
        appearance: "error",
      });
    }
  };

  return (
    <tr
      style={
        item.estado === "ACTIVO"
          ? { background: "#DCF2F9" }
          : { background: "white" }
      }
    >
      <TablaBodyTexto data-label="Tratamientos">
        {`${item.tratamiento}|${item.tipo ? item.tipo : ""}`}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Diente">
        <TablaBodyTextoDiente>
          {item.dientes.length > 0 ? item.dientes.join() : ""}
        </TablaBodyTextoDiente>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Total">
        <StyledInput
          type="number"
          value={item.precio}
          name="precio"
          min="0"
          max="999999999"
          step="0.01"
          placeholder="Precio.."
          onChange={handleChange}
          onBlur={handleBlur}
        ></StyledInput>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Dscto">
        <StyledInput
          type="number"
          value={item.dscto?item.dscto:0}
          name="dscto"
          min="0"
          max="999999999"
          step="0.01"
          placeholder="Dscto.."
          onChange={handleChange}
          onBlur={handleBlur}
        ></StyledInput>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Nota">
        <StyledInputNota
          name="nota"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.nota}
          autoComplete="off"
          style={{ width: "85% !important" }}
          readOnly={item.estado === "ACTIVO" ? true : false}
        ></StyledInputNota>
      </TablaBodyTexto>
      <TablaBodyTexto
        data-label="Estado"
        style={
          item.estado === "ACTIVO" ? { color: "red" } : { color: "#045184" }
        }
      >
        {item.estado}{" "}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Accion">
        {item.estado === "PENDIENTE" && (
          <IconButton
            className="button-icon"
            aria-label=""
            onClick={() => onClickIniciar(item)}
          >
            <Tooltip TransitionComponent={Zoom} placement="top" title="Iniciar">
              <PlayArrowIcon style={{ color: "#0089ff" }} />
            </Tooltip>
          </IconButton>
        )}

        {item.estado === "PENDIENTE" && (
          <IconButton
            className="button-icon"
            aria-label=""
            onClick={() => handleEliminar(item)}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Eliminar"
            >
              <DeleteIcon style={{ color: "red" }} />
            </Tooltip>
          </IconButton>
        )}
        {/* <DropDownPrestaciones item={item} onClick={handleClick} /> */}
      </TablaBodyTexto>
    </tr>
  );
};

export const PresupuestoTable = (props) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState({});
  const sesion = useSelector((state) => state.sesion);
  const cuenta = useSelector((state) => state.cuenta);
  const { addToast } = useToasts();
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    dispatch(setPrestation(item));
    setModalIsOpen(true);
  };
  const handleCloseModal = (item) => {
    setModalIsOpen(false);
  };
  const history = useHistory();
  const agregaNotaInicioPrestacion = (prestacion) => {
    const nota = {
      fecha: Date.now(),
      nota: "Iniciada",
      especialista: sesion.usuario.email,
    };
    prestacion.notasClinicas = [nota, ...prestacion.notasClinicas];
  };

  const handleIniciar = () => {
    //presta.precio = montoPago;
    try {
      validar(selectedItem);
      agregaNotaInicioPrestacion(selectedItem);
      dispatch(iniciarPrestacionTrx(selectedItem, cuenta));
      handleCloseModal();
      //history.push("/paciente/prestaciones");
      addToast("Prestación activada satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validar = (prestacion) => {
    if (prestacion.estado === "ACTIVO")
      throw new Error("Prestación en estado activo");
    if (prestacion.precio < 0) throw new Error("Precio inválido");
  };
  if (sesion.loading || sesion.loading == undefined)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    );
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  else
    return (
      <div>
        <Tabla>
          <TablaHeader>
            <tr>
              <TablaHeaderTexto scope="col" width={"30%"}>
                Tratamiento
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"10%"}>
                Diente
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"15%"}>
                Total
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"10%"}>
                Dscto.
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"15%"}>
                Nota
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"10%"}>
                Estado
              </TablaHeaderTexto>
              <TablaHeaderTexto scope="col" width={"10%"}>
                Acción
              </TablaHeaderTexto>
            </tr>
          </TablaHeader>
          <TablaBody>
            {props.items.map((item) => {
              return (
                <Fila
                  key={item.id}
                  item={item}
                  onClickIniciar={handleOpenModal}
                />
              );
            })}
          </TablaBody>
        </Tabla>
        <div>
        
          <ConfirmacionSiNoModal
            mensaje="Desea iniciar esta prestación?"
            isOpen={modalIsOpen}
            handleCloseModal={handleCloseModal}
            onClickYes={handleIniciar}
          />
        </div>
      </div>
    );
};
